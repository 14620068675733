export const loadScript = (src, done) => {
  const script = document.createElement("script");
  script.src = src;
  script.onload = () => {
    done();
  };
  script.onerror = () => {
    done(new Error("Failed to load script " + src));
  };
  document.head.appendChild(script);
};

export const browserSupportsAllFeatures = () => {
  return window.Promise || window.IntersectionObserver;
};
