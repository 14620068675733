import { component } from "picoapp";

// In-viewport detection
import vsbl from "vsbl";

export default component((node, context) => {
  const theme = node.getAttribute("data-trigger-theme");

  // Emit theme change event
  function enter() {
    context.emit("theme", { theme });
  }

  // When a third of the node crosses into the viewport, change theme
  const unmount = vsbl(node, { threshold: 0.33333 })(enter, null);

  return unmount;
});
