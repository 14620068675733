import { picoapp } from "picoapp";

import themeTarget from "./components/theme-target";
import themeTrigger from "./components/theme-trigger";
import smoothScrollTrigger from "./components/smooth-scroll-trigger";
import ageVerificationDialog from "./components/age-verification-dialog";
import wave from "./components/wave/wave";

// Initially registered components
const components = {
  ageVerificationDialog,
  themeTrigger,
  themeTarget,
  smoothScrollTrigger,
  wave
};

// Lazy components available for registration
const lazyComponents = {
  locator: import("./components/locator/index.js"),
  video: import("./components/video/video.js")
};

// TODO: add `isLoaded` state per every lazy component. Automatically update that state then the component has been mounted
const app = picoapp(components, {
  theme: "aqua",
  dialogOpen: false,
  locatorIsLoaded: null,
  videoRatio: null,
  viewport: {
    width: window.innerWidth,
    height: window.innerHeight
  }
});

app.on("dialogToggled", ({ dialogOpen }) => {
  if (dialogOpen) {
    // Disable body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${window.scrollY}px`;
  } else {
    // Re-enable body scrolling
    document.body.style.position = "";
    document.body.style.top = "";
  }
});

export const LAZY_ATTR = "data-lazy-component";

export async function mountLazyComponent(handle) {
  try {
    const exists = Object.keys(lazyComponents).indexOf(handle) > -1;
    if (!exists) throw new Error(`${handle} is not a valid lazy component`);

    const component = await lazyComponents[handle].then(
      module => module.default
    );

    // Merge into the rest of the components
    app.add({ [handle]: component });

    // Bind lazy components to DOM
    app.mount(LAZY_ATTR);
  } catch (reason) {
    console.error(reason);
  }
}

export default app;
