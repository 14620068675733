import { component } from "picoapp";
import promisedScrollFn from "../utils/smooth-scroll-fn";

export default component((node, ctx) => {
  const target = document.querySelector(node.getAttribute("href"));

  node.addEventListener("click", handleClick);

  return node => {
    node.removeEventListener("click", handleClick);
  };

  function handleClick(e) {
    e.preventDefault();

    promisedScrollFn.then(smoothScrollIntoView => {
      smoothScrollIntoView(target, { block: "start" });
    });
  }
});
