import { mountLazyComponent, LAZY_ATTR } from "./app";

// Create observer
export default function initLoader() {
  try {
    const nodes = [...document.querySelectorAll(`[${LAZY_ATTR}]`)];

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const handle = entry.target.getAttribute(LAZY_ATTR);

          // Mount lazy component
          mountLazyComponent(handle).then(() => {
            // Remove from observation
            observer.unobserve(entry.target);
          });
        }
      });
    });

    nodes.forEach(node => observer.observe(node));
  } catch (error) {
    console.error(error);
  }
}
