// Needed to use transpiled generator functions
import "regenerator-runtime/runtime";

// Load polyfills if necessary
import { loadScript, browserSupportsAllFeatures } from "./utils/polyfill-utils";
browserSupportsAllFeatures()
  ? main()
  : loadScript("/scripts/polyfills.js", main);

// Load app
import app from "./app.js";

import paralless from "paralless";
import registerAnimations from "./scroll-animations";
import debounce from "./utils/debounce";
import lazyImages from "./lazy-images";
import inViewComponentLoader from "./in-view-component-loader.js";

// Returns an “init” function
const parallax = paralless("data-parallax");

// Returns an “init” function
const initAnimations = registerAnimations({
  attribute: "data-animate",
  reset: true,
  threshold: 0.25
});

// Main function
function main() {
  document.addEventListener("DOMContentLoaded", e => {
    lazyImages("img[data-lazy-image]");

    // Mount components
    app.mount();

    // Creates intersection observer that will lazy load components as they intersect the viewport
    inViewComponentLoader();

    // After 1 second, init animations
    setTimeout(initAnimations, 1000);

    // Init parallax
    parallax();
  });

  // On resize
  window.addEventListener(
    "resize",
    debounce(() => {
      // Update viewport dimensions state (used for video aspect ratio swapping)
      app.hydrate({
        viewport: {
          width: window.innerWidth,
          height: window.innerHeight
        }
      })();

      // Update parallax
      parallax();
    }, 250)
  );
}
