export default function debounce(fn, wait = 0, leading = false) {
  let timeout;
  return function(...args) {
    // eslint-disable-next-line no-invalid-this
    const context = this;

    const callback = () => {
      timeout = null;
      // Execute function (on trailing edge)
      if (!leading) fn.apply(context, args);
    };

    const callNow = leading && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(callback, wait);

    // Execute function (on leading edge)
    if (callNow) fn.apply(context, args);
  };
}
