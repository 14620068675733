const needsPonyfill = !("scrollBehavior" in document.documentElement.style);

export default (async () => {
  try {
    if (needsPonyfill) {
      return await loadPonyfill();
    }
    return smoothScrollIntoView;
  } catch (err) {
    console.log(err);
  }
})();

async function loadPonyfill() {
  return await import("smooth-scroll-into-view-if-needed").then(module => {
    return module.default;
  });
}

function smoothScrollIntoView(node, options) {
  node.scrollIntoView({ behavior: "smooth", ...options });
}
