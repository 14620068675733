import { component } from "picoapp";

import "./wave.css";

export default component((node, context) => {
  const textContent = node.textContent;
  let html = "";

  const template = (character, delay) => {
    // eslint-disable-next-line prettier/prettier
    return `<span class="Wave-translate" style="animation-delay: ${delay}ms;"><span class="Wave-rotate" style="animation-delay: ${delay}ms;">${character === " " ? "&nbsp;" : character}</span></span>`;
  };

  // Wrap characters
  for (let i = 0; i < textContent.length; i++) {
    html += template(textContent[i], i * 100);
  }

  node.innerHTML = html;
});
