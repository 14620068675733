function loadSiblingSourceEls(node) {
  [...node.parentElement.children]
    .filter(el => el.tagName === "SOURCE")
    .forEach(sourceEl => (sourceEl.srcset = sourceEl.dataset.srcset));
}

function loadImage(node) {
  node.onload = function() {
    this.classList.add("is-loaded");
  };
  node.src = node.dataset.src;
  if (node.dataset.srcset) node.srcset = node.dataset.srcset;

  // If inside <picture>, find sibling <source> els, set their srcset attributes
  if (node.parentElement.tagName === "PICTURE") {
    loadSiblingSourceEls(node);
  }
}

function observeImages(selector) {
  const images = [...document.querySelectorAll(selector)];
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage(entry.target);
          // Stop observing image
          observer.unobserve(entry.target);
        }
      });
    },
    {
      rootMargin: "50% 0px"
    }
  );

  images.forEach(image => observer.observe(image));
}

export default function lazyImages(selector) {
  try {
    observeImages(selector);
  } catch (e) {
    console.error(e);
  }
}
