import { component } from "picoapp";
import cookie from "js-cookie";
import A11yDialog from "a11y-dialog";

export default component((node, context) => {
  const ofAge = cookie.get("stray_age_verification") === "ofAge";

  // Create dialog
  const dialog = new A11yDialog(node);

  // Register handlers
  dialog.on("hide", () => {
    // Drop a cookie, signifying that the user is indeed 21 or over
    cookie.set("stray_age_verification", "ofAge", { expires: 30 });

    // Emit `toggleDialog` event
    context.emit("dialogToggled", { dialogOpen: false });
  });

  dialog.on("show", () => {
    // Emit `dialogToggled` event
    context.emit("dialogToggled", { dialogOpen: true });
  });

  if (!ofAge) {
    dialog.show();
  }

  return function unsubscribe() {
    dialog.destroy();
  };
});
